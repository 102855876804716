@import url("https://use.typekit.net/nja8nky.css");

.greenBox {
	background-color : #00AE42;
}

.redBox {
	background-color : #C40D3C;
}

.white {
	color: white;
	height: 18px;
	width: 18px;
	font-weight: bold;
}

.none {
	background-color : #D9D9D9;
}

.box {
	height: 32px;
	width: 32px;
	border-radius: 5px;
	display: flex;
	align-items : center;
	justify-content: center;
}

.weekdayText {
 	font-family: acumin-pro;
	font-size: 9px;
	line-height: 20px;
	width: 32px;
	display: flex;
	align-items :center;
	justify-content: center;
}