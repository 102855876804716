@import url("https://use.typekit.net/nja8nky.css");

.weeklyGoalRow {
	display : flex;
	flex-direction: row;
    align-items : center;
    gap: 5px;
    justify-content: space-around;
    padding-left: 50px;
}

.weeklyGoalColumn {
    display : flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.circle{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 5px;
}

.aspire-green{
    background-color: #00AE42;
}

.aspire-green-font{
    color: #00AE42;
}

.aspire-red{
    background-color : #C40D3C;
}

.aspire-red-font{
    color : #C40D3C;
}

.aspire-dark-gray {
    background-color : #6D6E71;
}

.aspire-dark-gray-font {
    color : #6D6E71;
}

.legendRow {
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: acumin-pro;
    font-size: 14px;
    font-weight: bold;
}

.weekdayName {
	height: 32px;
	width: 32px;
	border-radius: 5px;
	display: flex;
	align-items : center;
	justify-content: center;
 	font-family: acumin-pro;
	font-size: 9px;
	line-height: 20px;
}

.lastColumn{
    margin-right: 5px;
}