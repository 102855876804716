@import url("https://use.typekit.net/nja8nky.css");

.mdhui-layout {
    padding: calc(env(safe-area-inset-top, 0px)) 0 16px 0;
    margin-bottom: calc(env(safe-area-inset-bottom, 0px));
}

.mdhui-card {
    margin-left: 11px!important;
    margin-right: 11px!important;
    margin-top: 11px!important;
}

.aspireFont{
    font-family: acumin-pro;
    font-size: 12px;
}

.filterMargins{
    margin-bottom: 8px;
}

.clickableNav{
	cursor: pointer;
    display:flex;
    align-items: center;
}

.dateFilterRow{
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 20px;
    padding-top: 10px;
    align-items: center;
}

.bold{
    font-weight: bold;
    font-size: 20px;
    font-family: acumin-pro;
}