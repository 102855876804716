@import url("https://use.typekit.net/nja8nky.css");

.margins{
    margin-left: 22px;
}

.rewardCardMargin{
    margin-top: 20px;
    margin-bottom: 20px;
}

.rewardHeader{
    font-family: acumin-pro;
    font-size: 20px;
    font-weight: bold;
    color: #162A40;
    line-height: 24px;
    margin-bottom: 10px;
}

.rewardNormal{
    font-family: acumin-pro;
    font-size: 13px;
    color: #162A40;
    line-height: 20px;
}

.rewardTotal{
    font-weight: bold;
}

.aspireGreenFont{
    color: #00AE42;
}

.divRewardsProgress{
    margin-left: 14px;
    margin-right:39px;
    display:flex;
    width: 95%;
}

.progress-wrapper {
	width: 100%;
	padding-top: 8px; 
	display:flex;
    align-items: center;
}

.progress-bar {
    width: 100%;
}

::-webkit-progress-bar {
	background-color: #D9D9D9;
	border-radius: 15px;
}

::-webkit-progress-value {
	background: #00AE42;
	border-radius: 15px;
}

progress {
    -webkit-appearance: none;
}

.markerColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -10px;
}

.divCircleAchieved{
    height: 32px;
    width: 32px;
    border: 3px solid #00AE42;
    background: #00AE42;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.divCircleNotAchieved{
    height: 32px;
    width: 32px;
    border: 3px solid #D9D9D9;
    background: #D9D9D9;
    border-radius: 50%;
    z-index: 1;
    margin-top: 10px;
}

.rewardAccumulation{
    font-family: acumin-pro;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: #6D6E71;
    text-align: center;
}

.rewardDate{
    font-family: acumin-pro;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color:#FFFFFF;
}

.moveLeft{
    margin-left: -7.5px;
}
