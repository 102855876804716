@import url("https://use.typekit.net/nja8nky.css");

.mdhui-layout {
    padding: calc(env(safe-area-inset-top, 0px)) 0 16px 0;
    margin-bottom: calc(env(safe-area-inset-bottom, 0px));
}

.mdhui-card {
    margin-left: 11px!important;
    margin-right: 11px!important;
    margin-top: 11px!important;
}

.pastUserHeader{
    font-family: acumin-pro;
    font-size: 20px;
    font-weight: bold;
    color: #162A40;
    line-height: 24px;
    margin-bottom: 10px;
}

.pastUserNormal{
    font-family: acumin-pro;
    font-size: 13px;
    color: #162A40;
    line-height: 20px;
}

.pastUserDiv{
    margin-top: 20px;
    margin-bottom: 20px;
}

.margins{
    margin-left: 15px;
    margin-right: 15px;
}

.pagerForwardIconPadding{
    padding-left: 4px;
    padding-right: 7px;
}

.pagerBackwardIconPadding{
    padding-right: 4px;
    padding-left: 7px;
}

.pagerRow {
	width: 100%; 
	display:flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 13px;
    border-top: 1px;
    border-color: #D9D9D9;
    border-style: solid;
    border-left: 0px;
    border-bottom: 0px;
}

.pagerText {
    font-family:  'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
} 

.iconSize{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
}

.clickableNav{
	cursor: pointer;
    display:flex;
    align-items: center;
}