@import url("https://use.typekit.net/nja8nky.css");

.accordion {
  max-width: 768px;
}

.accordion-item {
  list-style: none;
  margin-top: 15px;
  padding-bottom: 0px;
}

.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.accordion-toggle h3 {
    margin: 0;
}

.accordianColumn {
  display : flex;
  flex-direction: column;
  margin-left: 5px;
  margin-bottom: 10px;
}

div .mdhui-card-title{
  padding: 0px!important;
  font-family: acumin-pro;
  font-size: 20px;
  color: #162A40;
  font-weight: bold;
}

.goalTitle1 {
  font-family: acumin-pro;
  font-size: 12px;
  color: #162A40;
}

.dataViewToggle {
  background: linear-gradient(180deg, #357FB8 0%, #2E6E9E 100%);
  color: #FFFFFF;
  font-family: acumin-pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 100px;
  height: 30px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.accordianTitleBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  height: 43px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.accordion-content{
  width:100%;
}

.leftMargins{
  margin-left: 5px;
}

.rightMargins{
  margin-right: 5px;
}

.topMargins {
  margin-top: 5px;
}