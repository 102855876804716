@import url("https://use.typekit.net/nja8nky.css");

.divStreak {
    height: 124px;
    display: flex;
    flex-direction: row;
}

.divTrophy {
    margin-top: 25px;
    margin-left: 30px;
    margin-bottom: 30px;
    margin-right: 40px;
}

.streakHeader{
    font-family: acumin-pro;
    font-size: 12px;
    color: #162A40;
}

.streakDetail{
    font-family: acumin-pro;
    font-size: 20px;
    font-weight: bold;
    color: #162A40;
}

.divStreakDetails{
    margin-top: 12px;
}