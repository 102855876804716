@import url("https://use.typekit.net/nja8nky.css");

.recharts-yAxis text {
    font-family: acumin-pro;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.76px;
    color: #162a40;
  }
  
  .recharts-xAxis text {
    font-family: acumin-pro;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    letter-spacing: 0.76px;
    text-transform: uppercase;
    color: #162a40;
  }

  .multiLineLegendText{
    font-family: acumin-pro;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  .recharts-legend-wrapper{
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .recharts-wrapper{
    background-color: #FFFFFF;
  }