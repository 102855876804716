@import url("https://use.typekit.net/nja8nky.css");

.weeklyStatusRow{
	display : flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	background-color : #FFFFFF;
	margin-top: 7px;
	margin-bottom: 7px;
	margin-right: 5px;
	width:100%
}

.weeklyStatusDays{
	display : flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	background-color : #FFFFFF;
	margin-right: 5px;
	justify-content: space-around;
	width:100%;
}

.rowHeader {
	width: 80px;
	font-size : 11px;
	font-family: acumin-pro;
	display: flex; 
	justify-content: flex-end;
	padding-left: 5px;
}

