details {
    border: 1px solid #aaa;
    border-radius: 8px;
    padding: 0.5em 0.5em 0.2em;
    position: relative;
}



details[open] {
    padding: 0.5em;
}

details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: 0.5em;
}

.FaqMainHeader {
    padding: 0.5em 0.5em 0;
}

.FaqResourceHeader {
    color: "A2A9AD";
    font-size: 2em;
    text-align: center;
}

.FaqResourceText {
    font-size: 0.9em;
    padding-left: 20px;
}

.FaqResourceDetail {
    font-size: 0.8em;
    padding-left: 20px;
}

.summary-title {
    user-select: none;
    font-weight: bold;
}

.summary-content {
    border-top: 1px solid #e2e8f0;
    cursor: default;
    padding: 1em;
    font-size: 0.8em;
    font-weight: 300;
    line-height: 1.5;
}

details:hover {
    cursor: pointer;
}

summary {
    list-style: none;
    padding: 1em;
}

summary:focus {
    outline: none;
}

summary:hover .summary-chevron-up svg {
    opacity: 1;
}

.summary-chevron-up svg {
    opacity: 0.5;
}

.summary-title {
    width: 90%;
    padding-right: 20px;
}

.summary-chevron-up,
	.summary-chevron-down {
		pointer-events: none;
		position: absolute;
		top: 0.75em;
		right: 1em;
		background-color: transparent;
	}

.summary-chevron-up,
	.summary-chevron-down svg {
        display: block;
    }

summary::-webkit-details-marker {
    display: none;
}